import React from "react"
import ReactDOM from "react-dom"
import { Controller } from "@hotwired/stimulus"

import RecipePage from "components/recipe_page"

export default class extends Controller {
  static targets = ["content", "paintList"]

  get props() {
    this._props = this._props || JSON.parse(this.data.get("props"))
    return this._props
  }

  connect() {
    if (this.hasPaintListTarget) {
      this.paintListTarget.innerHTML = ""
    }

    ReactDOM.render(
      <RecipePage
        paintListContainer={this.hasPaintListTarget ? this.paintListTarget : null}
        {...this.props}
      />,
      this.contentTarget
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.contentTarget)

    if (this.slideshow) {
      this.slideshow.destroy()
    }
  }

  switchToMethod(event) {
    event.preventDefault()

    this.element.classList.add("recipe-page--show-method")
    this.element.classList.remove("recipe-page--show-paints")
  }

  switchToPaints(event) {
    event.preventDefault()

    this.element.classList.add("recipe-page--show-paints")
    this.element.classList.remove("recipe-page--show-method")
  }
}
