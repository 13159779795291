import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get message() {
    return this.data.get("message")
  }

  connect() {
    this.element.form.addEventListener("submit", this.confirm.bind(this))
  }

  confirm(event) {
    if (!window.confirm(this.message)) {
      event.preventDefault()
    }
  }
}
