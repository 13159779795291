import * as React from "react"
import * as ReactDOM from "react-dom"
import { ReactElement } from "react"
import { Controller } from "@hotwired/stimulus"
import HobbyLog from "components/hobby_log"

export default class extends Controller {
  get component(): ReactElement {
    return <HobbyLog />
  }

  connect(): void {
    ReactDOM.render(this.component, this.element)
  }

  disconnect(): void {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}
