import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.clipboard = new ClipboardJS(this.buttonTarget, {
      text: () => this.data.get("value")
    })
  }

  disconnect() {
    this.clipboard.destroy()
  }

  select() {
    this.inputTarget.select()
  }
}
