import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"

export default class extends Controller {
  static targets = ["code", "copy"]

  connect() {
    this.clipboard = new ClipboardJS(this.copyTarget, {
      text: () => this.codeTarget.value
    })
  }

  disconnect() {
    this.clipboard.destroy()
  }

  copy(event) {
    event.preventDefault()
  }
}
