import { Controller } from "@hotwired/stimulus"
import {
  Chart,
  Colors,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from "chart.js"
import "chartjs-adapter-date-fns"

Chart.register(
  Colors,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
)

export default class extends Controller {
  static targets = ["canvas", "data"]

  static values = {
    datasets: Array
  }

  connect() {
    this.chart = new Chart(this.canvasTarget, {
      type: "line",
      data: {
        datasets: this.datasetsValue
      },
      options: {
        stepped: true,
        animation: false,
        scales: {
          y: {
            beginAtZero: true
          },
          x: {
            type: "time",
            time: {
              unit: "month"
            },
            ticks: {
              maxTicksLimit: 12
            }
          }
        }
      }
    })
  }

  dataTargetConnected(element) {
    const datasets = JSON.parse(element.dataset.datasets)

    this.chart.data.datasets = datasets
    this.chart.update()
  }
}
