import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    siteKey: String
  }

  declare siteKeyValue: string

  connect() {
    const element = this.element as HTMLElement
    const sitekey = this.siteKeyValue

    if (window.grecaptcha) {
      grecaptcha.ready(function () {
        grecaptcha.render(element, { sitekey })
      })
    } else {
      const interval = setInterval(function () {
        if (window.grecaptcha) {
          grecaptcha.ready(function () {
            grecaptcha.render(element, { sitekey })
          })
          clearInterval(interval)
        }
      }, 100)
    }
  }
}
